export const BubblesClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="70"
      height="70"
      viewBox="0 0 100 100"
    >
      <path
        fill="#8889b9"
        d="M13,27c-1.105,0-2,0.895-2,2s0.895,2,2,2s2-0.895,2-2S14.105,27,13,27z"
      ></path>
      <circle cx="77" cy="13" r="1" fill="#f1bc19"></circle>
      <circle cx="50" cy="50" r="37" fill="#e4e4f9"></circle>
      <circle cx="83" cy="15" r="4" fill="#f1bc19"></circle>
      <circle cx="87" cy="24" r="2" fill="#8889b9"></circle>
      <circle cx="81" cy="76" r="2" fill="#fbcd59"></circle>
      <circle cx="15" cy="63" r="4" fill="#fbcd59"></circle>
      <circle cx="25" cy="87" r="2" fill="#8889b9"></circle>
      <circle cx="18.375" cy="54.5" r="2.5" fill="#fff"></circle>
      <circle cx="77.5" cy="30.5" r="1.5" fill="#fff"></circle>
      <path
        fill="#5a5c70"
        d="M35.067,72.208c-4.077,0-7.392-3.316-7.392-7.392V34.948c0-4.076,3.316-7.392,7.392-7.392h29.867	c4.077,0,7.392,3.316,7.392,7.392v29.867c0,4.076-3.316,7.392-7.392,7.392H35.067z"
      ></path>
      <path
        fill="#472b29"
        d="M65,28.4c3.639,0,6.6,2.961,6.6,6.6v30c0,3.639-2.961,6.6-6.6,6.6H35c-3.639,0-6.6-2.961-6.6-6.6V35	c0-3.639,2.961-6.6,6.6-6.6H65 M65,27H35c-4.418,0-8,3.582-8,8v30c0,4.418,3.582,8,8,8h30c4.418,0,8-3.582,8-8V35	C73,30.582,69.418,27,65,27L65,27z"
      ></path>
      <circle cx="50" cy="50" r="18.62" fill="#fff"></circle>
      <path
        fill="#472b29"
        d="M50,69c-10.477,0-19-8.523-19-19s8.523-19,19-19s19,8.523,19,19S60.477,69,50,69z M50,31.76 c-10.057,0-18.24,8.183-18.24,18.24S39.943,68.24,50,68.24S68.24,60.057,68.24,50S60.057,31.76,50,31.76z"
      ></path>
      <path
        fill="#472b29"
        d="M50,52c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S51.103,52,50,52z"
      ></path>
      <path
        fill="#472b29"
        d="M50,50.5c-0.058,0-0.116-0.01-0.173-0.031l-9.5-3.5c-0.259-0.096-0.392-0.383-0.296-0.642 s0.387-0.391,0.642-0.296l9.5,3.5c0.259,0.096,0.392,0.383,0.296,0.642C50.395,50.375,50.203,50.5,50,50.5z"
      ></path>
      <path
        fill="#472b29"
        d="M35.5,56c-0.201,0-0.392-0.123-0.468-0.323c-0.098-0.258,0.032-0.547,0.29-0.645l14.5-5.5 c0.259-0.101,0.547,0.032,0.646,0.29c0.098,0.258-0.032,0.547-0.29,0.645l-14.5,5.5C35.619,55.99,35.559,56,35.5,56z"
      ></path>
      <circle cx="50" cy="50" r="1" fill="#ff991f"></circle>
      <path
        fill="#472b29"
        d="M50,37c-0.276,0-0.5-0.224-0.5-0.5v-2c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v2 C50.5,36.776,50.276,37,50,37z"
      ></path>
      <path
        fill="#472b29"
        d="M43.25,38.808c-0.172,0-0.341-0.089-0.433-0.25l-1-1.732c-0.139-0.239-0.057-0.545,0.183-0.683 c0.238-0.139,0.545-0.057,0.683,0.183l1,1.732c0.139,0.239,0.057,0.545-0.183,0.683C43.421,38.787,43.335,38.808,43.25,38.808z"
      ></path>
      <path
        fill="#472b29"
        d="M38.309,43.75c-0.085,0-0.171-0.021-0.25-0.067l-1.732-1c-0.239-0.138-0.321-0.444-0.183-0.683 s0.444-0.32,0.683-0.183l1.732,1c0.239,0.138,0.321,0.444,0.183,0.683C38.649,43.66,38.48,43.75,38.309,43.75z"
      ></path>
      <path
        fill="#472b29"
        d="M36.5,50.5h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S36.776,50.5,36.5,50.5z"
      ></path>
      <path
        fill="#472b29"
        d="M36.576,58.25c-0.172,0-0.341-0.09-0.433-0.25c-0.139-0.239-0.057-0.545,0.183-0.683l1.732-1 c0.238-0.138,0.545-0.057,0.683,0.183c0.139,0.239,0.057,0.545-0.183,0.683l-1.732,1C36.747,58.229,36.661,58.25,36.576,58.25z"
      ></path>
      <path
        fill="#472b29"
        d="M42.25,63.924c-0.085,0-0.171-0.021-0.25-0.067c-0.239-0.138-0.321-0.444-0.183-0.683l1-1.732 c0.138-0.24,0.443-0.319,0.683-0.183c0.239,0.138,0.321,0.444,0.183,0.683l-1,1.732C42.591,63.834,42.422,63.924,42.25,63.924z"
      ></path>
      <path
        fill="#472b29"
        d="M50,66c-0.276,0-0.5-0.224-0.5-0.5v-2c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v2 C50.5,65.777,50.276,66,50,66z"
      ></path>
      <path
        fill="#472b29"
        d="M57.75,63.924c-0.172,0-0.341-0.089-0.433-0.25l-1-1.732c-0.139-0.239-0.057-0.545,0.183-0.683 c0.237-0.138,0.545-0.058,0.683,0.183l1,1.732c0.139,0.239,0.057,0.545-0.183,0.683C57.921,63.902,57.835,63.924,57.75,63.924z"
      ></path>
      <path
        fill="#472b29"
        d="M63.424,58.25c-0.085,0-0.171-0.021-0.25-0.067l-1.732-1c-0.239-0.138-0.321-0.444-0.183-0.683 c0.138-0.24,0.444-0.319,0.683-0.183l1.732,1c0.239,0.138,0.321,0.444,0.183,0.683C63.765,58.16,63.596,58.25,63.424,58.25z"
      ></path>
      <path
        fill="#472b29"
        d="M65.5,50.5h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S65.776,50.5,65.5,50.5z"
      ></path>
      <path
        fill="#472b29"
        d="M61.691,43.75c-0.172,0-0.341-0.09-0.433-0.25c-0.139-0.239-0.057-0.545,0.183-0.683l1.732-1 c0.238-0.139,0.545-0.057,0.683,0.183c0.139,0.239,0.057,0.545-0.183,0.683l-1.732,1C61.862,43.729,61.776,43.75,61.691,43.75z"
      ></path>
      <path
        fill="#472b29"
        d="M56.75,38.808c-0.085,0-0.171-0.021-0.25-0.067c-0.239-0.138-0.321-0.444-0.183-0.683l1-1.732 c0.138-0.24,0.444-0.32,0.683-0.183c0.239,0.138,0.321,0.444,0.183,0.683l-1,1.732C57.091,38.719,56.922,38.808,56.75,38.808z"
      ></path>
      <path
        fill="#ff991f"
        d="M50,67c-0.138,0-0.25-0.112-0.25-0.25v-20.5c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25 v20.5C50.25,66.888,50.138,67,50,67z"
      ></path>
    </svg>
  );
};
