import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import MaskedInput from 'react-text-mask';
import { fetchDoctorById, bookAppointment } from '../../../store/doctorSlice';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.locale('ru');

const BookingDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('day'));
  const [selectedTime, setSelectedTime] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '', phone: '' });

  const doctor = useSelector((state) => state.doctors.selectedDoctor);

  useEffect(() => {
    if (id) {
      dispatch(fetchDoctorById(id));
    }
  }, [id, dispatch]);

  const notifySuccess = () => {
    toast.success(
      'Вы оставили заявку на прием, наш персонал с вами свяжется в ближайшее время!',
      {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Zoom,
      }
    );
  };
  const notifyError = () => {
    toast.error('Ошибка при записи на прием', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Zoom,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date.startOf('day'));
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const validateForm = () => {
    let valid = true;
    let errors = { name: '', email: '', phone: '' };

    if (!/^[a-zA-Zа-яА-Я\s]+$/.test(name)) {
      errors.name = 'Имя должно содержать только буквы.';
      valid = false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Введите корректный адрес электронной почты.';
      valid = false;
    }
    if (!/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(phone)) {
      errors.phone = 'Введите корректный номер телефона.';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleBookingSubmit = async (e) => {
    e.preventDefault();
    if (!selectedTime) {
      alert('Пожалуйста, выберите время.');
      return;
    }
    if (!validateForm()) {
      return;
    }

    const appointmentData = {
      id: uuidv4(),
      date: selectedDate.format('DD.MM.YYYY'),
      time: selectedTime.format('HH:mm'),
      name,
      email,
      phone,
    };

    try {
      await dispatch(
        bookAppointment({ doctorId: id, appointment: appointmentData })
      );
      notifySuccess();
    } catch (error) {
      console.error('Ошибка при записи на прием:', error);
      notifyError();
    }
  };

  const isDateAvailable = (date) => {
    const dayName = date.format('dddd').toUpperCase();
    return doctor?.schedule?.days
      ?.map((day) => day.toUpperCase())
      .includes(dayName);
  };

  const isTimeSlotBooked = (slot) => {
    const slotDate = dayjs(slot).format('DD.MM.YYYY');
    const slotTime = dayjs(slot).format('HH:mm');

    return doctor?.appointments?.some((appt) => {
      return appt?.date === slotDate && appt?.time === slotTime;
    });
  };

  const timeSlots = [];
  let startTime = selectedDate.hour(doctor?.schedule?.hours[0] || 8).minute(0);
  let endTime = selectedDate.hour(doctor?.schedule?.hours[1] || 17).minute(0);
  let currentTime = startTime.clone();

  while (currentTime.isBefore(endTime)) {
    timeSlots.push(currentTime.clone());
    currentTime = currentTime.add(30, 'minute');
  }

  return (
    <>
      {doctor ? (
        <>
          <ToastContainer />
          <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black font-montserrat text-lg">
            ЗАПИСЬ НА ПРИЕМ
          </h2>
          <section className="flex flex-col text-base mb-4 tablet:mb-8">
            <h3 className="py-2 flex justify-center gap-2 text-[18px]">
              <span>Врач</span>
              <span>-</span>
              <span className="font-semibold">{doctor.name}</span>
            </h3>
            <p className="pt-2 pb-2 font-medium">Выберите дату:</p>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                shouldDisableDate={(date) => !isDateAvailable(date)}
                disablePast
              />
            </LocalizationProvider>
            <p className="pt-4 pb-2 font-medium">Выберите время:</p>
            <div className="grid grid-cols-3 gap-4 mt-4">
              {timeSlots.map((slot, index) => {
                const isSlotBooked = isTimeSlotBooked(slot);
                const isSelected =
                  selectedTime && selectedTime.isSame(slot, 'minute');

                // Добавляем проверку, недоступна ли выбранная дата (selectedDate)
                const isDateUnavailable = !isDateAvailable(selectedDate);

                return (
                  <button
                    key={index}
                    onClick={() => handleTimeSelect(slot)}
                    className={`p-2 rounded-lg ${
                      isSlotBooked || isDateUnavailable
                        ? 'bg-bgdarkgray text-white cursor-not-allowed' // Кнопка отключена, если слот занят или дата недоступна
                        : isSelected
                        ? 'bg-themeColor text-white'
                        : 'bg-bggray hover:bg-themeColor hover:text-white'
                    }`}
                    disabled={isSlotBooked || isDateUnavailable} // Делаем кнопку недоступной, если слот занят или дата недоступна
                  >
                    {slot.format('HH:mm')}
                  </button>
                );
              })}
            </div>
            <section className="py-6">
              {selectedTime ? (
                <div className="flex justify-center">
                  <p className="pb-2 font-medium">
                    Выбранное вами время{' - '}
                    <span className="font-bold">
                      {dayjs(selectedDate).format('D MMMM')}, {'  '}
                    </span>
                    <span className="font-bold">
                      {selectedTime.format('HH:mm')}
                    </span>
                  </p>
                </div>
              ) : (
                <p className="flex justify-center font-medium">
                  Дата визита еще не выбрана
                </p>
              )}
            </section>
            <section className="flex flex-col gap-2">
              <h3 className="pb-2 font-medium">Заполните контактные данные:</h3>
              <div className="mb-4">
                <input
                  required
                  id="name"
                  type="text"
                  placeholder="Ф. И. О."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div className="mb-4">
                <input
                  required
                  id="email"
                  type="email"
                  placeholder="Электронная почта"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="mb-4">
                <MaskedInput
                  mask={[
                    '+',
                    '7',
                    ' ',
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Телефон"
                  render={(ref, props) => (
                    <input
                      ref={ref}
                      {...props}
                      className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  )}
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>
            </section>
            <button
              onClick={handleBookingSubmit}
              className="mt-4 bg-themeColor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Записаться
            </button>
          </section>
        </>
      ) : (
        <p>Doctor not found</p>
      )}
    </>
  );
};

export default BookingDetails;
