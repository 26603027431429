import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import formatPhoneNumber from '../../features/formatPhone';

const Patients = () => {
  const contacts = useSelector((state) => state.contacts.data);

  if (!contacts) {
    return <div>Loading...</div>;
  }

  const { phone } = contacts;
  const prettyPhone = formatPhoneNumber(phone);

  const filePath = `${process.env.REACT_APP_API_URL}/uploads/documents/nalogoviy-vychet-zayvlenie.docx`;

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        ПАЦИЕНТАМ
      </h2>
      <section className="mt-1 mb-4 flex flex-col gap-5 laptop:gap-12 text-base font-montserrat text-graytext">
        <article className="">
          <h3 className="text-[28px] leading-[32px] font-medium text-center desktop:text-left">
            Первый визит
          </h3>
          <div className="flex flex-col gap-2 mt-3">
            <p>
              Мы хотим, чтобы отношения между пациентом доктором были
              доверительными.
            </p>
            <p>
              Не надо приходить домой и судорожно начинать читать в Интернете
              расшифровки анализов и диагнозов, доктор расскажет все на приеме
              сам. Ответит на вопросы (даже если кажется, что они нелепые или
              глупые), прокомментирует процесс приема и манипуляции, которые
              совершает.
            </p>
            <p>
              Но чтобы вы получили максимальную пользу от посещения Медицинского
              центра рекомендуем подготовиться:
            </p>
            <ul>
              <li>
                <CheckIcon />
                <span className="ml-1">
                  Возьмите с собой документы ( паспорт для пациентов старше 18
                  лет). При первом посещении заключается договор на оказание
                  платных медицинских услуг (Постановление Правительства РФ №
                  1006 от 4.10.2012 "Об утверждении Правил предоставления
                  медицинскими организациями платных медицинских услуг").
                </span>
              </li>
              <li>
                <CheckIcon />
                <span className="ml-1">
                  Если планируется прием у ребенка, то необходимо присутствие
                  одного из родителей с паспортом и свидетельством о рождении
                  ребенка. Если ребенка сопровождают не родители, то мы попросим
                  предоставить нотариально заверенное согласие родителей или
                  законных представителей.
                </span>
              </li>
              <li>
                <CheckIcon />
                <span className="ml-1">
                  Чтобы успеть на первый прием вовремя, просим приехать минут за
                  10-15, чтобы оформить необходимые документы без спешки.
                </span>
              </li>
              <li>
                <CheckIcon />
                <span className="ml-1">
                  Возьмите с собой результаты предыдущих приемов, исследований и
                  анализов, если до этого наблюдались не в нашем центре.
                  Подготовьте вопросы для доктора, это поможет снять волнение
                  перед первым приемом и не растеряться.
                </span>
              </li>
            </ul>
            <p>
              Прием специалистов в <b>Медицинском центре на Демидова</b>
              {'\u00A0'}
              осуществляется по записи. Записаться можно через форму обратной
              связи на сайте, у администратора или используя телефон:{' '}
              <b>
                <a href={`tel:${phone}`}>{prettyPhone}</a>
              </b>
            </p>
            <p>
              Если у докторов есть свободное время, то обязательно примем и без
              предварительной записи.
            </p>
          </div>
        </article>
        <article className="">
          <h3 className="text-[28px] leading-[32px] font-medium text-center desktop:text-left">
            Налоговый вычет
          </h3>
          <div className="flex flex-col gap-4 mt-3">
            <p>
              Налоговый вычет - это часть уже уплаченного вами налога НДФЛ
              (13%), которую можно вернуть.
            </p>
            <p>
              Налоговое законодательство предусматривает возврат части средств,
              которые налогоплательщик потратил на получение медицинских услуг
              (п.3 ст.219 Налогового кодекса РФ). Это могут быть средства,
              потраченные на прием врача, диагностику, стоматологические услуги,
              протезирование, лечение в дневном стационаре.
            </p>
            <p>
              Вы можете вернуть деньги, которые потратили на собственное
              лечение, детей до 18 лет, супруги (супруга) или родителей.
            </p>
            <div>
              <p>
                Для получения справки, заполните{' '}
                <a
                  href={filePath}
                  download="nalogoviy-vychet-zayvlenie.docx"
                  className="text-themeColor underline"
                >
                  заявление
                </a>
                .
              </p>
            </div>
            <p>
              Заполненное заявление передайте администратору вместе с копией
              вашего ИНН, договором на оказание услуг и чеками (их копиями). Мы
              подготовим справку для налоговых органов и свяжемся с вами, когда
              она будет готова. Срок изготовления справки - 30 рабочих дней.
            </p>
            <p>
              Уточнить готовность справки можно по телефонам клиники указанным в
              контактах.
            </p>
            <p>
              Копия лицензии для подачи документов в налоговые органы не
              требуется, так как ее номер прописан в договоре на оказание услуг
              (письмо ФНС от 31.08.2006 №САЭ-6-04/876).
            </p>
          </div>
        </article>

        <article className="">
          <h3 className="text-[28px] leading-[32px] font-medium text-center desktop:text-left">
            Прием жалоб и претензий
          </h3>
          <div className="flex flex-col gap-1 mt-3">
            <p>Дорогие пациенты!</p>
            <p>
              Если у вас остались какие-либо жалобы после посещения нашего
              медицинского центра, просьба сообщить об этом нашему{' '}
              <b>координатору</b>, по номеру:{' '}
              <b>
                <a href={`tel:${phone}`}>{prettyPhone}</a>
              </b>
            </p>
            <p className="font-semibold">
              Помните, ваша обратная связь помогает нам стать лучше!
            </p>
          </div>
        </article>
      </section>
    </>
  );
};

export default Patients;
