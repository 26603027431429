import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';

const ManageContacts = () => {
  const dispatch = useDispatch();

  const [contacts, setContacts] = useState({
    workingRegime: {
      days: ['Понедельник', 'Воскресенье'],
      hours: ['08:00', '22:00'],
    },
    email: '',
    phone: '',
    address: '',
  });

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/contacts`
      );

      if (response.data) {
        setContacts(response.data);
      } else {
        console.log('No contacts found');
      }
    } catch (error) {
      console.error('Ошибка при получении контактов:', error);
    }
  };

  const handleSaveContacts = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/contacts/1`,
        contacts
      );

      alert('Контакты успешно сохранены');
      dispatch(fetchContacts()); // Принудительное обновление данных после сохранения
    } catch (error) {
      console.error('Ошибка при сохранении контактов:', error);
    }
  };

  const handleChange = (name, value) => {
    if (name === 'workingRegimeStart' || name === 'workingRegimeEnd') {
      const days = [...contacts.workingRegime.days];
      if (name === 'workingRegimeStart') {
        days[0] = value;
      } else {
        days[1] = value;
      }
      setContacts({
        ...contacts,
        workingRegime: {
          ...contacts.workingRegime,
          days,
        },
      });
    } else if (
      name === 'workingRegimeStartHour' ||
      name === 'workingRegimeEndHour'
    ) {
      const hours = [...contacts.workingRegime.hours];
      if (name === 'workingRegimeStartHour') {
        hours[0] = value;
      } else {
        hours[1] = value;
      }
      setContacts({
        ...contacts,
        workingRegime: {
          ...contacts.workingRegime,
          hours,
        },
      });
    } else {
      setContacts({ ...contacts, [name]: value });
    }
  };

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black font-montserrat text-xl">
        Панель управления контактами
      </h2>
      <h4 className="mb-4">
        Контактные данные указанные на этой странице, отображаются по всему
        сайту (прим.: страница контактов, футер, страница пациентов и т.д.)
      </h4>
      <section className="flex flex-col relative grow">
        <TextField
          label="Дни работы - первый рабочий день недели"
          name="workingRegimeStart"
          value={contacts.workingRegime?.days?.[0] || ''}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Дни работы - последний рабочий день недели"
          name="workingRegimeEnd"
          value={contacts.workingRegime?.days?.[1] || ''}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Часы работы - начало рабочего дня"
          name="workingRegimeStartHour"
          value={contacts.workingRegime?.hours?.[0] || ''}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Часы работы - конец рабочего дня"
          name="workingRegimeEndHour"
          value={contacts.workingRegime?.hours?.[1] || ''}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={contacts.email || ''}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          fullWidth
          margin="normal"
        />
        <p>
          Формат телефона для корректного отображения должен начинаться с "+7"
        </p>
        <TextField
          label="Телефон"
          name="phone"
          value={contacts.phone || ''}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          fullWidth
          margin="normal"
        />
        <p>
          *Обязательно поставьте запятую(,) после города для корректного
          отображения в компонентах сайта. (пример: г. Михайловск, ул. Демидова,
          д. 142/2)
        </p>
        <TextField
          label="Адрес"
          name="address"
          value={contacts.address || ''}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          fullWidth
          margin="normal"
        />
        <div className="block mx-auto pt-2">
          <Button
            variant="contained"
            color="primary"
            style={{ width: '200px' }}
            onClick={handleSaveContacts}
          >
            Сохранить
          </Button>
        </div>
      </section>
    </>
  );
};

export default ManageContacts;
