// ManageHomepage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, TextField } from '@mui/material';

const ManageHomepage = () => {
  const [homepageData, setHomepageData] = useState(null);
  const [title, setTitle] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const [newFact, setNewFact] = useState('');
  const [newDoctor, setNewDoctor] = useState('');
  const [newService, setNewService] = useState('');

  useEffect(() => {
    fetchHomepageData();
  }, []);

  const fetchHomepageData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/homepage`
      );
      setHomepageData(response.data);
      setTitle(response.data.title || '');
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTitleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/homepage/title`, {
        title,
      });
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при обновлении заголовка:', error);
    }
  };

  // Обработчики для фотографий
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadPhoto = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      alert('Пожалуйста, выберите файл.');
      return;
    }

    const formData = new FormData();
    formData.append('photo', selectedFile);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/homepage/photo`,
        formData
      );
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при загрузке фотографии:', error);
    }
  };

  const handleDeletePhoto = async (index) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/homepage/photo/${index}`
      );
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при удалении фотографии:', error);
    }
  };

  // Обработчики для фактов
  const handleAddFact = async (e) => {
    e.preventDefault();

    if (!newFact) {
      alert('Пожалуйста, введите факт.');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/homepage/fact`, {
        fact: newFact,
      });
      setNewFact('');
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при добавлении факта:', error);
    }
  };

  const handleDeleteFact = async (index) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/homepage/fact/${index}`
      );
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при удалении факта:', error);
    }
  };

  // Обработчики для доступных врачей
  const handleAddDoctor = async (e) => {
    e.preventDefault();

    if (!newDoctor) {
      alert('Пожалуйста, введите имя врача.');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/homepage/doctor`, {
        doctor: newDoctor,
      });
      setNewDoctor('');
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при добавлении врача:', error);
    }
  };

  const handleDeleteDoctor = async (index) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/homepage/doctor/${index}`
      );
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при удалении врача:', error);
    }
  };

  // Обработчики для доступных услуг
  const handleAddService = async (e) => {
    e.preventDefault();

    if (!newService) {
      alert('Пожалуйста, введите название услуги.');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/homepage/service`,
        { service: newService }
      );
      setNewService('');
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при добавлении услуги:', error);
    }
  };

  const handleDeleteService = async (index) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/homepage/service/${index}`
      );
      fetchHomepageData();
    } catch (error) {
      console.error('Ошибка при удалении услуги:', error);
    }
  };

  if (!homepageData) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <h2 className="flex justify-center w-full  my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        Панель управления информацией на главной
      </h2>

      {/* Секция заголовка */}
      <section className="flex flex-col gap-2 py-4">
        <form onSubmit={handleTitleUpdate} style={{ marginBottom: '6px' }}>
          <TextField
            value={title}
            onChange={handleTitleChange}
            label="Заголовок страницы"
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
          >
            Обновить Заголовок
          </Button>
        </form>
        {/* Секция фактов */}
        <div className="flex flex-col gap-2 py-2 border-y-2 border-mainblue">
          <h3 className="text-lg text-center">
            Факты о клинике (отображаются с галочкой):
          </h3>
          <form onSubmit={handleAddFact}>
            <TextField
              value={newFact}
              onChange={(e) => setNewFact(e.target.value)}
              label="Новый Факт"
              fullWidth
              style={{ marginBottom: '12px' }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginBottom: '10px' }}
            >
              Добавить Факт
            </Button>
          </form>
          <ul className="flex flex-col gap-2 pb-2">
            {homepageData.facts.map((fact, index) => (
              <li
                key={index}
                className="border-[1px] border-mediumgray  p-2 rounded flex items-center"
              >
                <Button
                  style={{
                    marginRight: '8px',
                    display: 'flex',
                    flexShrink: '0',
                  }}
                  onClick={() => handleDeleteFact(index)}
                >
                  Удалить
                </Button>
                {fact}
              </li>
            ))}
          </ul>
        </div>
        {/* Секция доступных врачей */}
        <div className="flex flex-col gap-2 py-2 border-b-2 border-mainblue">
          <h3 className="text-lg text-center">
            Блок "в нашем центре ведут прием":
          </h3>
          <form onSubmit={handleAddDoctor}>
            <TextField
              value={newDoctor}
              onChange={(e) => setNewDoctor(e.target.value)}
              label="Новый Врач"
              fullWidth
              style={{ marginBottom: '12px' }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginBottom: '10px' }}
            >
              Добавить Врача
            </Button>
          </form>
          <ul className="flex flex-col gap-2 pb-2">
            {homepageData.availableDoctors.map((doctor, index) => (
              <li
                key={index}
                className="border-[1px] border-mediumgray  p-2 rounded flex items-center gap-2"
              >
                <Button onClick={() => handleDeleteDoctor(index)}>
                  Удалить
                </Button>
                {doctor}
              </li>
            ))}
          </ul>
        </div>
        {/* Секция доступных услуг */}
        <div className="flex flex-col gap-2 py-2">
          <h3 className="text-lg text-center"> Блок "Производятся" услуги:</h3>
          <form onSubmit={handleAddService}>
            <TextField
              value={newService}
              onChange={(e) => setNewService(e.target.value)}
              label="Новая Услуга"
              fullWidth
              style={{ marginBottom: '12px' }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginBottom: '10px' }}
            >
              Добавить Услугу
            </Button>
          </form>
          <ul className="flex flex-col gap-2 pb-2">
            {homepageData.availableServices.map((service, index) => (
              <li
                key={index}
                className="border-[1px] border-mediumgray  p-2 rounded flex items-center gap-2"
              >
                <Button onClick={() => handleDeleteService(index)}>
                  Удалить
                </Button>
                {service}
              </li>
            ))}
          </ul>
        </div>
        {/* Секция фотографий */}
        <div className="flex flex-col gap-2 pt-2 border-t-2 border-mainblue">
          <h3 className="text-lg text-center">Фотографии карусели</h3>
          <form className="" onSubmit={handleUploadPhoto}>
            <input
              className="w-full pb-4"
              type="file"
              onChange={handleFileChange}
            />
            <Button variant="contained" color="primary" type="submit">
              Загрузить Фотографию
            </Button>
          </form>
          <h3 className="text-lg text-center border-t-[1px] border-mainblue mt-4 pt-2">
            Загруженные фотографии
          </h3>

          <ul>
            {homepageData.photos.map((photo, index) => (
              <li key={index} className="flex flex-col gap-2 py-2">
                <img
                  src={`${process.env.REACT_APP_API_URL}/${photo}`}
                  alt={`Фото ${index}`}
                  style={{ width: '100%' }}
                  className='rounded-lg'
                />
                <Button onClick={() => handleDeletePhoto(index)}>
                  Удалить
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default ManageHomepage;
