import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import InputFileUpload from './InputFileUpload';

const DAYS_OF_WEEK = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

const EditDoctorModal = ({ open, onClose, doctor, onChange, onSave }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...doctor, [name]: value });
  };

  const toggleDaySelection = (day) => {
    onChange({
      ...doctor,
      days: doctor.days.includes(day)
        ? doctor.days.filter((d) => d !== day)
        : [...doctor.days, day],
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange({ ...doctor, photo: file });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Редактировать Доктора</DialogTitle>
      <DialogContent>
        <div className="flex flex-col my-4">
          <TextField
            required
            id="login"
            label="Логин:"
            name="login"
            value={doctor?.login || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
          />
          <TextField
            required
            id="password"
            label="Пароль:"
            type="password"
            name="password"
            value={doctor?.password || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
          />
          <TextField
            required
            id="email"
            label="Email:"
            type="email"
            name="email"
            value={doctor?.email || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
          />
          <TextField
            required
            id="name"
            label="Ф.И.О:"
            name="name"
            value={doctor?.name || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            variant="filled"
          />
          <TextField
            required
            id="specialty"
            label="Специальность:"
            name="specialty"
            value={doctor?.specialty || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            variant="filled"
          />
          <TextField
            id="experience"
            label="Опыт работы:"
            type="number"
            name="experience"
            value={doctor?.experience || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
          />
          <TextField
            id="education"
            label="Образование:"
            name="education"
            value={doctor?.education || ''}
            onChange={handleInputChange}
            style={{ width: '100%', marginBottom: '16px' }}
            variant="filled"
          />
          <div className="flex flex-col gap-1">
            <label
              htmlFor="about"
              className="text-sm font-medium text-gray-700"
            >
              Произвольная информация:
            </label>
            <textarea
              id="about"
              name="about"
              placeholder="О специалисте:"
              value={doctor?.about || ''}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 text-gray-700 bg-[#E8E8E8] border-b border-graytext rounded-t shadow-sm focus:outline-none focus:ring-0  focus:border-b-mainblue focus:border-b-2 transition duration-150 ease-in-out"
            />
          </div>

          <div className="my-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2">
              Дни работы{' '}
              <span className="font-light">
                (выбранные дни будут доступны пациентам для записи онлайн)
              </span>
              :
            </label>
            <div className="flex flex-wrap gap-2">
              {DAYS_OF_WEEK.map((day) => (
                <button
                  key={day}
                  type="button"
                  onClick={() => toggleDaySelection(day)}
                  className={`text-white py-1 px-2 rounded ${
                    doctor?.days?.includes(day)
                      ? 'bg-mainblue text-white'
                      : 'bg-bgdarkgray'
                  }`}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2">
              Часы работы{' '}
              <span className="font-light">
                (выбранный промежуток будет доступен пациентам для записи с интервалом 30 мин.)
              </span>
            </label>
            <div className="flex gap-4">
              <TextField
                required
                id="startHour"
                label="От"
                type="number"
                value={doctor?.startHour || 8}
                onChange={(e) =>
                  onChange({ ...doctor, startHour: parseInt(e.target.value) })
                }
                InputProps={{ inputProps: { min: 0, max: 23 } }}
                sx={{ mb: 2, width: '100px' }}
              />
              <TextField
                required
                id="endHour"
                label="До"
                type="number"
                value={doctor?.endHour || 16}
                onChange={(e) =>
                  onChange({ ...doctor, endHour: parseInt(e.target.value) })
                }
                InputProps={{ inputProps: { min: 0, max: 23 } }}
                sx={{ mb: 2, width: '100px' }}
              />
            </div>
          </div>
          <div>
            <InputFileUpload handleFileChange={handleFileChange} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Отмена
        </Button>
        <Button onClick={onSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDoctorModal;
