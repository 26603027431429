import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchDoctors = createAsyncThunk(
  'doctors/fetchDoctors',
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/doctors`
    );
    return response.data;
  }
);

export const fetchDoctorById = createAsyncThunk(
  'doctors/fetchDoctorById',
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/doctors/${id}`
    );
    return response.data;
  }
);

export const submitReview = createAsyncThunk(
  'doctors/submitReview',
  async ({ doctorId, reviewData }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/doctors/${doctorId}/reviews`,
      reviewData
    );
    return response.data;
  }
);

export const bookAppointment = createAsyncThunk(
  'doctors/bookAppointment',
  async ({ doctorId, appointment }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/doctors/${doctorId}/appointments`,
      { appointment }
    );
    return response.data;
  }
);

const doctorSlice = createSlice({
  name: 'doctors',
  initialState: {
    doctors: [],
    selectedDoctor: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    removeDoctor: (state, action) => {
      state.doctors = state.doctors.filter(
        (doctor) => doctor.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDoctors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.doctors = action.payload;
      })
      .addCase(fetchDoctors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchDoctorById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDoctorById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedDoctor = action.payload;

        // Инициализируем поля reviews и appointments, если они null
        if (!state.selectedDoctor.reviews) {
          state.selectedDoctor.reviews = [];
        }
        if (!state.selectedDoctor.appointments) {
          state.selectedDoctor.appointments = [];
        }
      })
      .addCase(fetchDoctorById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        // Инициализируем массив, если он еще не был инициализирован
        if (!state.selectedDoctor.reviews) {
          state.selectedDoctor.reviews = [];
        }
        state.selectedDoctor.reviews.push(action.payload);
      })
      .addCase(bookAppointment.fulfilled, (state, action) => {
        // Инициализируем массив, если он еще не был инициализирован
        if (!state.selectedDoctor.appointments) {
          state.selectedDoctor.appointments = [];
        }
        state.selectedDoctor.appointments.push(action.payload);
      });
  },
});

export const { removeDoctor } = doctorSlice.actions;
export default doctorSlice.reducer;
