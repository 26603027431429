import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';

const Dashboard = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem('isAuthenticated');
    logout();
    navigate('/admin-login');
  };

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black font-montserrat text-xl">
        Панель управления сайтом
      </h2>
      <button
        onClick={handleLogout}
        className="bg-bgdarkgray hover:bg-bgdarkgray text-white hover:text-white hover:bg-graytext p-2 rounded"
      >
        ВЫХОД
      </button>
      <div className="mt-4 flex flex-col grow justify-between">
        <section className="flex justify-between gap-4 w-full">
          <div className=" flex flex-col w-[330px] justify-between gap-3">
            <Button
              variant="contained"
              onClick={() => navigate('/manage-homepage')}
            >
              Управлять главной страницей
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/manage-services')}
            >
              Управлять услугами
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/manage-promo')}
            >
              Управлять акциями
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/manage-licenses')}
            >
              Управлять лицензиями
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/manage-reviews')}
            >
              Управлять отзывами о клинике
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/manage-contacts')}
            >
              Управлять контактами
            </Button>
          </div>
          <div className="flex flex-col w-[330px] justify-start gap-3">
            <Button
              variant="contained"
              onClick={() => navigate('/register-doctor')}
            >
              Зарегистрировать доктора
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/manage-doctors')}
            >
              Управлять базой докторов
            </Button>
          </div>
        </section>
        <div className="flex mx-auto w-[200px] flex-col"></div>
      </div>
    </>
  );
};

export default Dashboard;
