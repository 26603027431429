// ErrorPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import errorImage from '../../assets/homepage/error_404.jpg';

const ErrorPage = () => {
  return (
    <div
      className="flex flex-col items-center justify-center grow bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${errorImage})` }}
    >
      <div className="bg-white bg-opacity-75 p-8 rounded-lg shadow-md mx-4">
        <h1 className="text-3xl tablet:text-5xl font-bold mb-4 text-center">
          404 - Страница не найдена
        </h1>
        <p className="text-lg tablet:text-xl text-center mb-8">
          К сожалению, страница, которую вы ищете, не существует.
        </p>
        <div className="flex justify-center">
          <Link to="/">
            <Button variant="contained" color="primary">
              Вернуться на главную
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
