import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const ManageOffers = () => {
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newOffer, setNewOffer] = useState({
    title: '',
    description: '',
    startDate: '',
    endDate: '',
  });
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/offers`
        );
        setOffers(response.data);
      } catch (error) {
        console.error('Ошибка при получении акций:', error);
      }
    };

    fetchOffers();
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleChange = (e) => {
    setNewOffer({ ...newOffer, [e.target.name]: e.target.value });
  };

  const handleAddOrUpdateOffer = async () => {
    try {
      const formData = new FormData();
      formData.append('title', newOffer.title);
      formData.append('description', newOffer.description);
      formData.append('startDate', newOffer.startDate);
      formData.append('endDate', newOffer.endDate);

      if (image) {
        formData.append('image', image);
      }

      if (selectedOffer) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/offers/${selectedOffer.id}`,
          formData
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/offers`,
          formData
        );
      }

      setNewOffer({ title: '', description: '', startDate: '', endDate: '' });
      setImage(null);
      setSelectedOffer(null);
      setIsModalOpen(false);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/offers`
      );
      setOffers(response.data);
    } catch (error) {
      console.error('Ошибка при добавлении/обновлении акции:', error);
    }
  };

  const handleEditOffer = (offer) => {
    setSelectedOffer(offer);
    setNewOffer({
      title: offer.title,
      description: offer.description,
      startDate: offer.startDate,
      endDate: offer.endDate,
    });
    setIsModalOpen(true);
  };

  const handleDeleteOffer = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/offers/${id}`);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/offers`
      );
      setOffers(response.data);
    } catch (error) {
      console.error('Ошибка при удалении акции:', error);
    }
  };

  const handleClearForm = () => {
    setSelectedOffer(null);
    setNewOffer({ title: '', description: '', startDate: '', endDate: '' });
    setImage(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black font-montserrat text-lg">
        Панель управления акциями
      </h2>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Добавить новую акцию
      </Button>
      <h2 className="text-xl font-bold mt-6 mb-4 border-t-[1px] border-graytext py-2">
        Список существующих акций
      </h2>
      <ul className="gap-4 flex flex-col">
        {offers.map((offer) => (
          <li key={offer.id}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel-content-${offer.id}`}
                id={`panel-header-${offer.id}`}
              >
                <h3 className="font-bold text-lg text-graytext">
                  {offer.title}
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <article className="flex flex-col gap-6">
                  <div>
                    <p>
                      <b>Описание/дополнительный текст для акции:</b>
                    </p>
                    <p>{offer.description}</p>
                  </div>
                  <div className="">
                    <p>
                      <b>Фоновое изображения акции:</b>
                    </p>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${offer.image}`}
                      loading="lazy"
                      alt={offer.title}
                      style={{
                        width: '100%',
                        maxHeight: '300px',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      <b>Промежуток в который акция действует:</b>
                    </p>
                    <p>
                      с {new Date(offer.startDate).toLocaleDateString()} по{' '}
                      {new Date(offer.endDate).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex gap-2 mt-2">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleEditOffer(offer)}
                    >
                      Редактировать
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteOffer(offer.id)}
                    >
                      Удалить
                    </Button>
                  </div>
                </article>
              </AccordionDetails>
            </Accordion>
          </li>
        ))}
      </ul>

      <Dialog open={isModalOpen} onClose={handleClearForm}>
        <Button
          onClick={handleClearForm}
          color="secondary"
          sx={{ marginTop: '12px' }}
        >
          Закрыть окно
        </Button>
        <DialogTitle>
          {selectedOffer ? 'Редактировать акцию' : 'Добавить новую акцию'}
        </DialogTitle>
        <DialogContent>
          <div className="my-4">
            <TextField
              label="Название"
              name="title"
              value={newOffer.title}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Описание"
              name="description"
              value={newOffer.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Дата начала акции"
              name="startDate"
              type="date"
              value={newOffer.startDate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Дата окончания акции"
              name="endDate"
              type="date"
              value={newOffer.endDate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="mb-4">
            <p className="py-2 font-semibold">
              Загрузите изображение для акции:
            </p>
            <input type="file" onChange={handleImageChange} />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'justify-between',
            gap: '14px',
          }}
        >
          <Button onClick={handleAddOrUpdateOffer} color="primary">
            {selectedOffer ? 'Сохранить изменения' : 'Добавить акцию'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageOffers;
