import React from 'react';
import { useSelector } from 'react-redux';
import tel from '../../assets/contacts/icons8-phone.webp';
import mail from '../../assets/contacts/icons8-email.webp';
import location from '../../assets/contacts/icons8-location.webp';
import instagramIcon from '../../assets/contacts/icons8-instagram.webp';
import building_photo from '../../assets/contacts/building_outside.webp';
import { BubblesClockIcon } from '../../assets/contacts/bubbles_clock';
import YandexMap from '../subcomponents/YandexMap';
import formatPhoneNumber from '../../features/formatPhone';

const Contacts = () => {
  const contacts = useSelector((state) => state.contacts.data);
  const instagram = 'mc_na_demidova'; // ! link is not edditable

  if (!contacts) {
    return <div>Loading...</div>;
  }

  const { workingRegime, email, phone, address } = contacts;
  const prettyPhone = formatPhoneNumber(phone);
  const arr = address.split(',');
  const [city, ...street] = arr;
  const strLeast = street.join(',');

  return (
    <>
      <h2 className="flex justify-center w-full  my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        КОНТАКТНАЯ ИНФОРМАЦИЯ
      </h2>
      <section className="flex flex-col">
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="flex justify-center lg:w-2/3 bg-bggray rounded-lg">
            <img
              className="object-cover rounded-lg p-1"
              src={building_photo}
              alt="фото здания"
            />
          </div>
          <aside className="flex flex-col  laptop:w-2/5 w-full rounded-lg bg-bggray laptop:items-center laptop:align-center p-4">
            <div className="flex flex-col  w-full justify-between gap-4 lg:flex-col lg:gap-8">
              <div className="flex flex-row w-full grow justify-between gap-4 shrink lg:flex-col lg:min-w-full">
                <div className="flex flex-row gap-2 w-full grow justify-center">
                  <div className="flex flex-col justify-center">
                    <BubblesClockIcon />
                  </div>
                  <div className="flex flex-col">
                    <h2 className="w-[270px] text-center font-medium">
                      Режим работы
                    </h2>
                    <p className="w-[270px] text-center">
                      {workingRegime.days[0]} - {workingRegime.days[1]}
                    </p>
                    <p className="w-[270px] text-center">
                      {workingRegime.hours[0]} - {workingRegime.hours[1]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full grow justify-between gap-4 shrink lg:flex-col lg:min-w-full">
                <div className="flex flex-row gap-2 w-full grow justify-center">
                  <div className="flex flex-col justify-center h-[70px] w-[70px] shrink-0">
                    <img src={location} alt="адресс" className="" />
                  </div>
                  <div className="flex flex-col">
                    <h2 className="w-[270px] text-center font-medium">Адрес</h2>
                    <p className="w-[270px] text-center">{city}</p>
                    <p className="w-[270px] text-center"> {strLeast}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full grow justify-between gap-4 shrink lg:flex-col lg:min-w-full">
                <div className="flex flex-row gap-2 w-full grow justify-center">
                  <div className="flex flex-col justify-center  h-[70px] w-[70px] shrink-0">
                    <img src={tel} alt="телефон" className="" />
                  </div>
                  <div className="flex flex-col justify-evenly ">
                    <h2 className="w-[270px] text-center font-medium">
                      Телефон
                    </h2>
                    <a className="w-[270px] text-center" href={`tel:${phone}`}>
                      {prettyPhone}
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full grow justify-between gap-4 shrink lg:flex-col lg:min-w-full">
                <div className="flex flex-row gap-2 w-full grow justify-center">
                  <div className="flex flex-col justify-center  h-[70px] w-[70px] shrink-0">
                    <img src={mail} alt="почта" className="" />
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <h2 className="w-[270px] text-center font-medium">
                      E-mail
                    </h2>
                    <a
                      className="w-[270px] text-center"
                      href={`mailto:${email}`}
                    >
                      {email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full grow justify-between gap-4 shrink lg:flex-col lg:min-w-full">
                <div className="flex flex-row gap-2 w-full grow justify-center">
                  <div className="flex flex-col justify-center  h-[70px] w-[70px] shrink-0">
                    <img src={instagramIcon} alt="инстаграм" className="" />
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <h2 className="w-[270px] text-center font-medium">
                      Instagram
                    </h2>
                    <a
                      className="w-[270px] text-center"
                      href={`https://www.instagram.com/${instagram}`}
                    >
                      @{instagram}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </section>
      <YandexMap />
    </>
  );
};

export default Contacts;
