import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../subcomponents/ImageModal';
import Loading from '../subcomponents/Loading';

const License = () => {
  const [licenses, setLicenses] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchLicenses = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/licenses`
      );
      setLicenses(response.data);
    };

    fetchLicenses();
  }, []);

  const openModal = (imagePath) => {
    setSelectedImage(imagePath);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        ЛИЦЕНЗИИ
      </h2>
      {licenses.length ? (
        <section className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-2 gap-4 p-2">
          {licenses.map((license) => (
            <div
              key={license.id}
              className="gap-4 cursor-pointer transition-transform duration-300 hover:scale-105"
              onClick={() => openModal(license.imagePath)}
            >
              <img
                src={`${process.env.REACT_APP_API_URL}/${license.imagePath}`}
                loading="lazy"
                alt={license.title}
                className="w-full h-auto rounded-lg max-w-[380px] mx-auto"
              />
            </div>
          ))}
        </section>
      ) : (
        <Loading />
      )}
      {selectedImage && (
        <Modal onClose={closeModal}>
          <img
            src={`${process.env.REACT_APP_API_URL}/${selectedImage}`}
            alt="License"
            className="w-full h-auto rounded-lg"
          />
        </Modal>
      )}
    </>
  );
};

export default License;
