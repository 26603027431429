import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Avatar, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchDoctors } from '../../../store/doctorSlice';
import DoctorCategories from './DoctorCategories';
import Loading from '../../subcomponents/Loading';

const ColorButton = styled(Button)(({ theme }) => ({
  height: 32,
  color: '#ffff',
  backgroundColor: '#308F8E',
  '&:hover': {
    backgroundColor: '#1976D2',
  },
  fontSize: 14,
}));

const Doctors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doctors = useSelector((state) => state.doctors.doctors);
  const status = useSelector((state) => state.doctors.status);
  const error = useSelector((state) => state.doctors.error);
  const [filteredDoctors, setFilteredDoctors] = useState(doctors);

  useEffect(() => {
    dispatch(fetchDoctors());
  }, [dispatch]);

  useEffect(() => {
    setFilteredDoctors(doctors);
  }, [doctors]);

  const handleCategorySelect = (category) => {
    setFilteredDoctors(
      doctors.filter((doctor) =>
        doctor.specialty
          .split(', ')
          .some((spec) =>
            spec.toLowerCase().includes(category ? category.toLowerCase() : '')
          )
      )
    );
  };

  const displaySpecialities = (specialities) => {
    const specArray = specialities.split(',').map((spec) => spec.toUpperCase());
    return (
      <div className="flex flex-col gap-1">
        {specArray.map((spec, idx) => (
          <span key={idx} className="text-center text-themeColor text-[13px]">
            {spec}
          </span>
        ))}
      </div>
    );
  };

  // if (status === 'loading') {
  //   return <Typography>Loading...</Typography>;
  // }

  if (status === 'failed') {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <h2 className="flex justify-center my-2 py-2 pl-2 border-[1px] border-bggray rounded-lg bg-bggray text-black font-montserrat text-lg">
        НАШИ СПЕЦИАЛИСТЫ
      </h2>
      {doctors.length ? (
        <div>
          <DoctorCategories
            doctors={doctors}
            onSelectCategory={handleCategorySelect}
          />
          <section className="mt-4 grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 laptop:grid-cols-3 gap-2 place-content-between">
            {filteredDoctors.map((doctor) => (
              <div
                className="flex flex-col mb-1 p-2 h-[272px] border-bggray border-[2px] rounded-lg"
                style={{ minWidth: '305px', maxWidth: '600px' }}
                key={doctor.id}
              >
                <div className="flex flex-row mb-0 h-[130px]">
                  <Avatar
                    src={`${process.env.REACT_APP_API_URL}/${doctor.photo}`}
                    alt={doctor.name}
                    sx={{ width: 100, height: 120, mr: 2 }}
                    variant="rounded"
                  />
                  <div className="flex flex-col w-full">
                    <p className="font-normal mb-4 flex justify-center text-center">
                      {doctor.name}
                    </p>
                    <div className="font-semibold text-xs laptop:text-[14px] text-themeColor">
                      {displaySpecialities(doctor.specialty)}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col justify-evenly text-[14px] leading-8 mt-2">
                    <p className="flex justify-center items-center w-[100px] h-[32px] bg-bggray border-[1px] border-[#E5E7E3] rounded">
                      <span>Стаж:&nbsp;</span>
                      <span className="text-themeColor font-semibold">
                        {doctor.experience}
                      </span>
                      <span>&nbsp;лет</span>
                    </p>
                    <p className="mt-1 flex justify-center items-center w-[100px] h-[32px] bg-bggray border-[1px] border-[#E5E7E3] rounded">
                      <span>Отзывы:&nbsp;</span>
                      <span className="text-themeColor font-semibold">
                        {doctor.reviews ? doctor.reviews.length : 0}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="flex grow justify-center w-[100%] items-end">
                  <ColorButton
                    className="w-[100%]"
                    variant="contained"
                    onClick={() => navigate(`/doctors/${doctor.id}`)}
                  >
                    Подробнее
                  </ColorButton>
                </div>
              </div>
            ))}
          </section>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Doctors;
