import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReviews } from '../../store/reviewSlice';
import axios from 'axios';
import reviewIcon from '../../assets/reviews/icons8-review.png';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = () =>
  toast.success('Ваш отзыв успешно добавлен к остальным!', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Zoom,
  });

const Reviews = () => {
  const dispatch = useDispatch();
  const reviews = useSelector((state) => state.reviews.reviews);
  const status = useSelector((state) => state.reviews.status);
  const error = useSelector((state) => state.reviews.error);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [text, setText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const reviewsPerPage = 10;
  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  useEffect(() => {
    dispatch(fetchReviews());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/reviews/add`, {
        name,
        email,
        phone,
        text,
      });
      notify();
      dispatch(fetchReviews());
      setName('');
      setEmail('');
      setPhone('');
      setText('');
    } catch (error) {
      console.error('Error adding review:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  return (
    <>
      <ToastContainer />
      <h2 className="flex justify-center w-full  my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
        ОТЗЫВЫ
      </h2>
      {status === 'loading' ? (
        <section className="flex flex-col lg:flex-row-reverse">
          <div className="flex flex-col w-full lg:w-2/5  rounded-lg lg:ml-8 mb-6 lg:mb-0">
            <div className="bg-bggray nogrow rounded-lg lg:p-6 p-4"></div>
            <section className="flex flex-col w-full lg:w-3/5 bg-bggray rounded-lg p-4 lg:p-6">
              <div className="w-full"></div>
            </section>
          </div>
        </section>
      ) : status === 'failed' ? (
        <p className="flex justify-center text-xl">Error: {error}</p>
      ) : (
        <>
          <section className="flex flex-col lg:flex-row-reverse">
            <div className="flex flex-col w-full lg:w-2/5  rounded-lg lg:ml-8 mb-6 lg:mb-0">
              <form
                className="bg-bggray nogrow rounded-lg lg:p-6 p-4"
                onSubmit={handleSubmit}
              >
                <div className="text-lg flex justify-center">
                  <h2 className="lg:mb-6 mb-2 tablet:mb-4">
                    Оставьте свой отзыв
                  </h2>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="name"
                  >
                    Ф.И.О:
                  </label>
                  <input
                    required
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="email"
                  >
                    Email:
                  </label>
                  <input
                    required
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="phone"
                  >
                    Телефон:
                  </label>
                  <input
                    required
                    id="phone"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="text"
                  >
                    Текст отзыва:
                  </label>
                  <textarea
                    required
                    id="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="flex w-full items-center justify-end">
                  <button
                    type="submit"
                    className="bg-themeColor hover:bg-mainblue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Отправить
                  </button>
                </div>
              </form>
            </div>
            <section className="flex flex-col w-full lg:w-3/5 bg-bggray rounded-lg p-4 lg:p-6">
              <div className="w-full">
                <h2 className="text-lg mb-2 tablet:mb-4 flex justify-center">
                  Отзывы о нашей клинике
                </h2>
                <div className="flex justify-center mb-4">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={`mx-1 px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? 'bg-themeColor text-white'
                          : 'bg-white text-maingreen'
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <div className="flex flex-col w-full gap-4">
                  {currentReviews.map((review) => (
                    <article
                      key={review.id}
                      className="bg-white p-4 rounded shadow w-full"
                    >
                      <div className="flex flex-row w-full gap-4">
                        <div className=" border-black rounded-full bg-bggray px- w-[68px] h-[68px]">
                          <img className="w-[68px]" src={reviewIcon} alt="" />
                        </div>
                        <div className="flex flex-col flex-1">
                          <p className="font-medium text-lg">{review.name}</p>
                          <p className="mt-2 mb-4 px-4 py-1 text-gray-600 text-[14px] bg-themeColor text-white rounded w-[180px]">
                            Отзыв от: {review.date}
                          </p>
                          <p
                            className="font-sans text-[14px] tablet:text-base"
                            style={{ wordBreak: 'break-word' }}
                          >
                            {review.text}
                          </p>
                        </div>
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </>
  );
};

export default Reviews;
