const formatPhoneNumber = (phone) => {
  // Проверка, что телефон начинается с +7 и имеет 11 цифр
  if (phone.startsWith('+7') && phone.length === 12) {
    return `+7 (${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(
      8,
      10
    )}-${phone.slice(10, 12)}`;
  }
  return phone; // Возвращаем исходный номер, если он не соответствует ожиданиям
};

export default formatPhoneNumber;
