import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const notifyError = () => {
  toast.error('Ошибка при попытке входа в личный кабинет врача', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Zoom,
  });
};

const DoctorLogin = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Если врач уже авторизован, перенаправляем его на панель управления
    const doctorId = sessionStorage.getItem('doctorId');
    if (sessionStorage.getItem('isAuthenticated') === 'true' && doctorId) {
      navigate(`/doctor-dashboard/${doctorId}`);
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      // Проверка существования логина
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/doctors/login`,
        { login, password }
      );
      if (response.data.success) {
        // Сохраняем данные аутентификации в sessionStorage
        sessionStorage.setItem('isAuthenticated', 'true');
        sessionStorage.setItem('doctorId', response.data.doctor.id);
        // Переход на уникальную страницу доктора при успешной аутентификации
        navigate(`/doctor-dashboard/${response.data.doctor.id}`);
      } else {
        setError(
          response.data.message || 'Ошибка при входе. Проверьте данные.'
        );
      }
    } catch (error) {
      console.error('Ошибка при входе:', error);
      setError('Ошибка при входе. Проверьте данные.');
      notifyError();
    }
  };

  return (
    <div className="flex justify-center items-center my-auto">
      <ToastContainer />
      <div className="bg-white p-6 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl mb-4">Вход в профиль врача</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-sm mb-2">Логин:</label>
            <input
              type="text"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Введите логин"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-2">Пароль:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Введите пароль"
            />
          </div>
          {error && <p className="text-red text-base mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-maingreen text-white p-2 rounded"
          >
            Войти
          </button>
        </form>
      </div>
    </div>
  );
};

export default DoctorLogin;
