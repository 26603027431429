import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchReviews } from '../../../store/reviewSlice';
import {
  responsiveReviews,
  CustomDot,
} from '../../subcomponents/CarouselParts';
import reviewsFemale1 from '../../../assets/reviews/reviewsFemale1.webp';
import reviewsFemale2 from '../../../assets/reviews/reviewsFemale2.webp';
import reviewsMale1 from '../../../assets/reviews/reviewsMale1.webp';
import reviewsMale2 from '../../../assets/reviews/reviewsMale2.webp';
import reviewIcon from '../../../assets/reviews/icons8-review.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const avatarImages = [
  reviewsFemale1,
  reviewsFemale2,
  reviewsMale1,
  reviewsMale2,
];

const SmallReviews = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reviews = useSelector((state) => state.reviews.reviews);

  // Локальное состояние для случайных отзывов
  const [randomReviews, setRandomReviews] = useState([]);

  // Использование useEffect для обновления отзывов после загрузки
  useEffect(() => {
    dispatch(fetchReviews());
  }, [dispatch]);
  // Перерендерить компонент, когда изменяются отзывы
  useEffect(() => {
    if (reviews.length > 0) {
      setRandomReviews(getRandomReviews(reviews, Math.min(reviews.length, 5)));
    }
  }, [reviews]);

  // Функция для получения случайных отзывов
  const getRandomReviews = (reviews, count) => {
    const shuffled = [...reviews].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count).map((review, index) => ({
      ...review,
      avatar: avatarImages[index % avatarImages.length],
    }));
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '..';
    }
    return text;
  };

  return (
    <section className="mt-4 laptop:mb-8">
      <div className="flex flex-col items-center">
        <img src={reviewIcon} alt="отзывы" className="w-[68px] text-graytext" />
        <h2 className="text-3xl font-medium text-graytext">Отзывы</h2>
      </div>
      <Carousel
        responsive={responsiveReviews}
        ssr
        infinite={false}
        autoPlay={true}
        keyBoardControl={true}
        transitionDuration={2000}
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
        containerClass="carousel-container"
        customDot={<CustomDot />}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        showDots
      >
        {randomReviews.map((review) => (
          <div
            key={review.id}
            className="flex flex-col justify-center p-4 pb-6"
          >
            <div
              className="bg-white rounded-lg p-4 shadow-lg "
              style={{
                maxWidth: '730px',
                minWidth: '290px',
                height: '330px',
                overflow: 'hidden',
              }}
            >
              <div className="w-full">
                <div className="flex flex-row gap-4">
                  <img
                    src={review.avatar}
                    alt="avatar"
                    className="w-[50px] h-[50px] rounded-full mb-2 mt-1"
                  />
                  <div>
                    <h3 className="font-medium text-lg">{review.name}</h3>
                    <p className="text-sm text-gray-600">{review.date}</p>
                  </div>
                </div>
                <p
                  className="mt-2 overflow-hidden text-ellipsis mb-4"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 12,
                    overflow: 'hidden',
                  }}
                >
                  {truncateText(review.text, 300)}{' '}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="flex justify-center gap-2 mt-4">
        <button
          onClick={() => navigate('/reviews')}
          className="px-4 py-2 rounded-lg border-[1px] border-themeColor hover:bg-themeColor hover:text-white"
        >
          Оставить отзыв
        </button>
        <button
          onClick={() => navigate('/reviews')}
          className="px-4 py-2 rounded-lg border-[1px] border-themeColor hover:bg-themeColor hover:text-white"
        >
          Все отзывы
        </button>
      </div>
    </section>
  );
};

export default SmallReviews;
