// store/homepageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchHomepageData = createAsyncThunk(
  'homepage/fetchHomepageData',
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/homepage`
    );
    return response.data;
  }
);

const homepageSlice = createSlice({
  name: 'homepage',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomepageData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHomepageData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchHomepageData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default homepageSlice.reducer;
