import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarLower from './NavbarLower';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import tel from '../../assets/navbar/icons8-iphone-14-100.png';
import navbarLogo from '../../assets/navbar/logo-removebg-preview.webp';
import { useSelector } from 'react-redux';
import formatPhoneNumber from '../../features/formatPhone';

const MENU = [
  { label: 'Врачи', path: '/doctors' },
  { label: 'Услуги', path: '/services' },
  { label: 'Акции', path: '/promo' },
  { label: 'Пациентам', path: '/patients' },
  { label: 'Лицензии', path: '/license' },
  { label: 'Отзывы', path: '/reviews' },
  { label: 'Контакты', path: '/contacts' },
];

const ColorButton = styled(Button)(({ theme }) => ({
  height: 40,
  color: '#FFFF',
  backgroundColor: '#308F8E',
  '&:hover': {
    backgroundColor: '#1976D2',
  },
  fontSize: 13,
}));

const CombinedNavbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const contacts = useSelector((state) => state.contacts.data);

  const open = Boolean(anchorEl);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth < 555);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
      setIsPhoneScreen(window.innerWidth < 555);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const phone =
    contacts && typeof contacts.phone === 'string' ? contacts.phone : null;
  const prettyPhone = phone ? formatPhoneNumber(phone) : null;

  return (
    <header className="bg-navbargray shadow-none">
      <div className="flex justify-between items-center p-1 tablet:px-4 laptop:px-8 desktop:px-16 large:px-64">
        <div className="flex items-center shrink-0">
          <img
            src={navbarLogo}
            alt="Clinic Logo"
            className="h-12 tablet:h-14 rounded-lg cursor-pointer"
            onClick={() => navigate('/')}
          />
          <div className="hidden md:flex flex-col nowrap font-semibold font-montserrat text-themeColor"></div>
        </div>

        <div className="flex items-center gap-[10px]  ">
          <div className={` ${isSmallScreen ? 'block' : 'hidden'}`}>
            <IconButton onClick={handleClick}>
              <MenuIcon
                sx={{ width: '35px', height: '35px' }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                style: {
                  width: '100%',
                  left: 0,
                  right: 0,
                  top: '0px',
                  position: 'fixed',
                  backgroundColor: '#fff',
                },
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {MENU.map((item) => (
                <MenuItem
                  key={item.label}
                  onClick={() => handleNavigation(item.path)}
                  className="flex justify-center"
                  sx={{ justifyContent: 'center' }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
          {!isPhoneScreen && phone && (
            <div className="mr-1 flex items-center gap-1 tablet:pr-[px]">
              <div className="w-[50px]">
                <img src={tel} alt="телефон" className="" />
              </div>
              <div className="flex flex-col">
                <p className="font-montserrat text-bgdarkgray text-[13px]">
                  ЗАПИСЬ НА ПРИЕМ
                </p>
                <a
                  className="font-montserrat test-[13px] font-base underline-0"
                  href={`tel:${phone}`}
                >
                  {prettyPhone}
                </a>
              </div>
            </div>
          )}
          <ColorButton
            onClick={() => navigate('/booking')}
            startIcon={<AssignmentIcon />}
            variant=""
            sx={{ height: '50px', padding: '4px 12px' }}
          >
            {isSmallScreen ? <p>Запись онлайн</p> : <p>Запись онлайн</p>}
          </ColorButton>
        </div>
      </div>
      <div className="hidden navbarLowerWidth:block">
        <NavbarLower />
      </div>
    </header>
  );
};

export default CombinedNavbar;
