export const responsiveReviews = {
  desktop: {
    breakpoint: { max: 3000, min: 1160 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1160, min: 640 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 790, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const responsiveAbout = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const CustomDot = ({ onClick, ...rest }) => {
  const { active } = rest;
  return (
    <li
      className={active ? 'active' : 'inactive'}
      onClick={() => onClick()}
      style={{
        background: active ? '#28926E' : '#BFBFBF',
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        display: 'inline-block',
        margin: '0 5px',
      }}
    />
  );
};
